import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "@components/Layout";

const NotFound = () => {
  return (
    <>
      <Layout>
        <Container>
          <Row>
            <Col xs={12}>
              <div className="my-5">
                <h1>Not found!</h1>
              </div> 
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default NotFound;
